import { FC } from 'react'
import Particles from 'react-tsparticles'
import { loadSlim } from 'tsparticles-slim'

const SnowFall: FC = () => {
  const particlesInit = async (main: any) => {
    await loadSlim(main)
  }
  return (
    <div style={{ position: 'absolute', zIndex: '1' }}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          particles: {
            color: { value: '#fff' },
            move: {
              direction: 'bottom',
              enable: true,
              outModes: 'out',
              speed: 2,
            },
            number: {
              density: {
                enable: true,
              },
              value: 35,
            },
            opacity: {
              value: { min: 0.3, max: 1 },
            },
            shape: {
              type: 'image',
              options: {
                image: {
                  src: 'https://medpoint.pro/static/images/snow.png',
                },
              },
            },
            size: {
              value: { min: 3, max: 9 },
            },
            wobble: {
              enable: true,
              distance: 10,
              speed: { min: 4, max: 7.5 },
            },
          },
        }}
      />
    </div>
  )
}

export default SnowFall
