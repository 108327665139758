import { FC, useRef, useEffect } from 'react'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'

import MainLayout from 'containers/main-layout/main-layout'
import WidthLimiter from 'containers/width-limiter/width-limiter'
import SEOMetatags from 'components/parts/seo-metatags/seo-metatags'
import SEOBreadCrumbs from 'components/parts/seo-metatags/seo-breadcrumbs'
import ScrollEventWrap from 'containers/scroll-event-wrap/scroll-event-wrap'
import GetNMO from 'components/intro-alt/get-nmo/get-nmo'
import InfoLink from 'components/intro-alt/info-link/info-link'
import WhyUs from 'components/intro-alt/why-us/why-us'
import NearestWebinars from 'components/intro-alt/nearest-webinars/nearest-webinars'
import SnowFall from 'components/parts/snow-fall/snow-fall'
import IntroSpacer from 'components/intro-alt/intro-spacer/intro-spacer'
import IntroMedic from 'components/intro-alt/intro-medic/intro-medic'
import OwnEdition from 'components/intro-alt/own-edition/own-edition'
import CourseReviews from 'components/course/course-reviews/course-reviews'
import CourseMainSights from 'components/course/course-mainsights/course-mainsights'
import IntroBottomBlock from 'components/intro-alt/intro-bottom-block/intro-bottom-block'
import SEOContext from 'context/SEOContext'
import Auth from 'store/auth'
import usePCWindow from 'hooks/usePCWindow'
import useDisableAMO from 'hooks/useDisableAmo'

import checkTokenExist from 'utils/checkTokenExist'
import restRequest from 'utils/server-side/restRequest'
import { concatParams, getParams, cutHashFromURL } from 'utils/getURLData'
import { formatSpecialities } from 'store/lists/useSpecialities'
import { IndexPageSEO } from 'constants/SEO'
import { MainPageBC } from 'constants/SEO-breadcrumbs'
import { popupName } from 'constants/auth/auth-data'
import { ScreenSize } from 'constants/media-constanst'
import { A_INTRO_SCROLL } from 'constants/analytic-data'
import { MAIN_PAGE_URL, StatusVocabulary } from 'constants/data'

import styles from 'styles/main.module.scss'

interface IMainPromoPage {
  cards: string
}

const MainPromoPage: FC<IMainPromoPage> = ({ cards }: IMainPromoPage) => {
  const { webinars, specialities } = JSON.parse(cards)

  const containerRef = useRef<HTMLDivElement>(null)
  const { isAuthorized } = Auth.useAuth()
  const router = useRouter()
  const isMobile = usePCWindow(ScreenSize.mobile)
  const path = cutHashFromURL(router.asPath)
  useDisableAMO()

  const showRegistrationForm = () => {
    router.push(`${path}#${popupName.registration}`, undefined, { shallow: true })
  }

  useEffect(() => {
    if (!isAuthorized) return
    if (router.asPath.includes('#registration')) return

    const params = getParams(path)
    const formedParams = concatParams(params)
    const url = formedParams ? `${MAIN_PAGE_URL}?${formedParams}` : MAIN_PAGE_URL

    setTimeout(() => {
      // TODO Спросить у Андрея как обойтись без таймаута, тк без него не работает переход на main для зареганных (Может не регать пользователей на 1 этапе, а только на 2)
      router.push(url)
    }, 500)
  }, [isAuthorized, path, router.asPath])

  return (
    <>
      <SEOMetatags data={IndexPageSEO} />
      <SEOBreadCrumbs data={MainPageBC} />
      <SnowFall />
      <ScrollEventWrap containerRef={containerRef} event={A_INTRO_SCROLL}>
        <SEOContext.Provider value={IndexPageSEO}>
          <div ref={containerRef}>
            <MainLayout mainPage={true} noLines={true}>
              <div className={styles.bgGradient}>
                <GetNMO isMobile={isMobile} showRegistrationForm={showRegistrationForm} />
                <IntroSpacer />
                <InfoLink />
                <IntroSpacer />
                <WhyUs />
                <IntroSpacer />
                <WidthLimiter noLeftPadding noTopPadding noRightPadding noBottomPadding>
                  <CourseMainSights showMainSights={false} />
                </WidthLimiter>
                <NearestWebinars webinars={webinars} />
                <IntroSpacer />
                <OwnEdition />
                <IntroSpacer />
                <WidthLimiter noLeftPadding noTopPadding noRightPadding noBottomPadding>
                  <CourseReviews />
                </WidthLimiter>
                <IntroMedic />
                <IntroSpacer />
                <IntroBottomBlock specs={specialities} />
              </div>
            </MainLayout>
          </div>
        </SEOContext.Provider>
      </ScrollEventWrap>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const reqCookies = context.req.headers.cookie
  const isToken = checkTokenExist(reqCookies)
  const formedParams = concatParams(context.query)
  const url = formedParams ? `${MAIN_PAGE_URL}?${formedParams}` : MAIN_PAGE_URL

  if (isToken) {
    return {
      redirect: {
        destination: url,
        permanent: false,
      },
    }
  }

  const props = { webinars: [], specialities: [] as any[] }
  const webinarSearchParams = {
    limit: 4,
    StatusId: StatusVocabulary.FUTURE,
  }

  try {
    const webinarRequest = restRequest(context, 'get', `/api/webinar/`, webinarSearchParams)
    const specialities = restRequest(context, 'get', '/api/speciality', { limit: 1000 })
    const results = await Promise.all([webinarRequest, specialities])

    if (results.some((result) => result.status !== 200)) throw new Error('rest request error')

    props.webinars = results[0]?.data || []

    const formatedSpecialities = formatSpecialities(results[1]?.data)
    props.specialities = formatedSpecialities
  } catch (err) {
    console.log(err)
  }

  return {
    props: {
      cards: JSON.stringify(props),
    },
  }
}

export default MainPromoPage
